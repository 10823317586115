import React, { useEffect, useRef, useState } from "react";
import Container from "../components/chat-channel/Container";
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import { Swipeable, useSwipeable } from "react-swipeable";
import Layout from "../components/chat-channel/Layout";
import RegisterSection from "../components/chat-channel/ChannelHeader";
import { Slider } from "../components/common/sliderHomePage";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { SearchIcon, PlusIcon } from "../components/common/Icons";
import { RequestForm } from "../components/form";
import { FeatureArrowWithColor } from "../components/common/Icons";
import { useMedia } from "use-media";
import styled from "styled-components";
import { RequestFormModal } from "../components/modalform";
import {
  AutomateTestimonials,
  AutomateTestimonialsMobile,
} from "../components/testimonials/automate";

import { ISOSecurity_November } from "./conversational-ai-platform";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Section = styled.section`
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
`;
const Tab = styled.div`
  font-size: 20px;
  cursor: pointer;
  background: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 20px;
  ${({ active }) =>
    active &&
    `
    background: #F2FFFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    overflow: overlay;
  `}
`;
const Header = styled.h4`
  font-family: Roboto-Medium;
    font-size: 20px;
    color: #333;
    padding-bottom: 5px;
    width: 100%;
    float: left;
    margin-bottom: 0;
    line-height: normal;
}
`;
const Paragraph = styled.p`
flex: 1 1 auto;
color: #416183 !important;
font-family: Roboto-Regular;
line-height: 1;
margin-bottom: 0px;
font-size: 20px;
width:100%;
float:left;
}
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 49%;
  max-width: 49%;
`;
const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 0 0 49%;
  max-width: 49%;
`;
const Tabalign = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
`;

const topImage = require("../assets/automate/home/automate_home_header.png");
const topImageMobile = require("../assets/automate/home/automate_home_header.png");

const okta = require("../assets/images/icons-web/okta.svg");
const box = require("../assets/images/icons-web/box_1.svg");
const authO = require("../assets/images/icons-web/auth0.svg");
const bamboo = require("../assets/images/icons-web/bamboo.svg");
const servicenow = require("../assets/images/icons-web/servicenow.svg");
const ms_office = require("../assets/images/icons-web/ms_office.svg");
const jira = require("../assets/images/icons-web/jira.svg");
const freshservice = require("../assets/images/icons-web/freshservice.svg");
const jamf = require("../assets/images/icons-web/jamf.svg");
const zendesk = require("../assets/images/icons-web/zd_1.svg");
const zoho_people = require("../assets/images/icons-web/zoho_people_1.svg");
const ivanti = require("../assets/images/icons-web/ivanti.svg");
const ad = require("../assets/images/icons-web/zure.svg");
const jumpcloud = require("../assets/images/icons-web/jumpcloud.svg");
const intune = require("../assets/images/icons-web/ms_intune.svg");
const hexnode = require("../assets/images/icons-web/hexnode.svg");
const otrs = require("../assets/images/icons-web/otrs.svg");
const onelogin = require("../assets/images/icons-web/onelogin.svg");

const onboard_one = require("../assets/automate/home/automate_onboarding_1.png");
const onboard_two = require("../assets/automate/home/automate_onboarding_2.png");
const onboard_three = require("../assets/automate/home/automate_onboarding_3.png");
const onboard_four = require("../assets/automate/home/automate_onboarding_4.png");
const OnboardHere = require("../assets/automate/home/here_arrow.png");

const arrow_round = require(".././assets/img/home_page_assist/left_arrow.svg");
const arrow_round_mobile = require(".././assets/img/home_page_assist/left_arrow_mobile.svg");

const customerBanner = require("../assets/automate/home/automate_clients_logo.svg");
const bannerImageMobile = require("../assets/automate/home/automate_clients_logo_mobile.svg");

const slider_1 = require("../assets/automate/home/home_slider_1.png");
const slider_2 = require("../assets/automate/home/home_slider_2.png");
const slider_3 = require("../assets/automate/home/home_slider_3.png");
const slider_4 = require("../assets/automate/home/home_slider_4.png");

const slider_icon_1 = require("../assets/automate/home/features_icon_1.png");
const slider_icon_2 = require("../assets/automate/home/features_icon_2.png");
const slider_icon_3 = require("../assets/automate/home/features_icon_3.png");
const slider_icon_4 = require("../assets/automate/home/features_icon_4.png");

const Feature_Content_1 = require("../assets/automate/home/feature_content_1.png");
const Feature_Content_2 = require("../assets/automate/home/feature_content_2.png");
const Feature_Content_3 = require("../assets/automate/home/feature_content_3.png");
const Feature_Content_4 = require("../assets/automate/home/feature_content_4.png");
const Feature_Content_5 = require("../assets/automate/home/feature_content_5.png");

const img = require("../assets/img/feature/home/iso_sec_img.png");
const img_mob = require("../assets/img/feature/home/iso_mob_automate_home.svg");

const Section2_1 = require("../assets/automate/home/automate_sec_2_img_1_new.png");
const Section2_2 = require("../assets/automate/home/automate_sec_2_img_2_new.png");

const Section2_1_mobile = require("../assets/automate/home/automate_sec_2_img_1_new_mobile.png");
const Section2_2_mobile = require("../assets/automate/home/automate_sec_2_img_2_new_mobile.png");

// const cahe_director = require("../assets/images/testimonials/cahe_director.png");
// const cahe_logo = require("../assets/images/testimonials/cahe_logo.png");
// const quotes = require("../assets/images/testimonials/quotes.svg");

export default function FeatureHomePage() {
  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);

  const isSmall = useMedia({ maxWidth: "520px" });

  const onboardingSliderWithClick = () => setPlan("homepage");

  const betaClick = () => setPlan("homepage");
  return (
    <>
      <TitleAndMetaTags
        title="#1 Free Workflow Automation Platform | Workativ Automate"
        description="Workativ Automate: No-Code Workflow Automation – Easily automate workplace apps, tasks, and processes in minutes with Workativ's free no-code platform. Get started now!"
        keywords={["Workflow Automation"]}
        ogImage={topImage}
        schema={ldSchema}
      />
      <Container>
        <section className="workflow_automation_wrapper workflow_autoamte_wrapper trigger_page automate_product new_automate_background new_mobile_home_design resolution_automate_first_automate_home button_form_automate_popup automate_23_update">
          <Layout
            backgroundColor={"bg_header_automation"}
            logoFor="AUTOMATE"
            product="AUTOMATE"
            fromPage="automate-home"
          >
            <div className="automate_homepage">
              {isSmall ? (
                <section className="float-left width-100 automate-home-header-main-section">
                  <div className="container automate-home-header-main-section-flex">
                    <h1 className="font-page-header">
                      Connect your cloud apps instantly. Automate work. Get
                      productive.
                    </h1>
                    <p className="font-section-normal-text mb-0">
                      Have a repetitive process? Automate it in minutes. No
                      coding required.
                    </p>
                    <RequestForm
                      product="automate"
                      AutomateCTA
                      isFooterForm={false}
                      fromPage="automate-home"
                    />
                    <p className="font-section-sub-header-small text-align-center color-black">
                      {" "}
                      Free sign up. Free to use.
                    </p>
                    <img src={topImageMobile}></img>
                  </div>
                </section>
              ) : (
                <RegisterSection
                  altImage={"Workflow Automation platform"}
                  rightImage={topImage}
                  backgroundColor={"bg_header_automation"}
                  bannerImage={customerBanner}
                  bannerImageMobile={bannerImageMobile}
                  additionalClassContainer={"margin-automate-header-logo"}
                  additionalClassBanner={"automate_header_banner_client_logo"}
                  customerText={"Join leading innovators"}
                  fromPage="automate-home"
                >
                  <RegisterSection.FormWithContent
                    product="automate"
                    content=""
                    fromPage="automate-home"
                  >
                    <RegisterSection.MainHeader
                      additionalClassHeader={"font-page-header-automate"}
                    >
                      Connect your cloud apps instantly. <br></br>Automate work.
                      Get productive.
                    </RegisterSection.MainHeader>
                    <RegisterSection.SubHeader additionalClassSubHeader="workflow_automate_feature_paragraph">
                      Have a repetitive process? Automate it in minutes. No
                      coding required.
                    </RegisterSection.SubHeader>
                  </RegisterSection.FormWithContent>
                </RegisterSection>
              )}
            </div>

            {isSmall ? <CenterAutomateMobile /> : <CenterAutomate />}
            {isSmall ? (
              <AutomateTestimonialsMobile isHeader={true} />
            ) : (
              <AutomateTestimonials isHeader={true} />
            )}
            <SwipeableTop />
            {isSmall ? <CustomerMobile /> : null}
            {isSmall ? (
              <OnboardingMobile
                onboardingSliderWithClick={betaClick}
                tabContent={tabsForOnBoarding_November}
                imagesToPreload={tabsForOnBoardingImagesDesktop_November}
              />
            ) : (
              <OnboardingSlider
                onboardingSliderWithClick={betaClick}
                tabContent={tabsForOnBoarding_November}
                imagesToPreload={tabsForOnBoardingImagesDesktop_November}
              />
            )}
            <div className="container-fluid autoamte_apps">
              <InegrationApps />
            </div>

            <ISOSecurity_November />

            <div className="automate_homepage">
              <RequestForm
                product="automate"
                content="Start on our free plan, and scale up as you grow."
                AutomateCTA
                automateContent
                autoamteButton
                automateName
                isFooterForm={true}
                fromPage="automate-home"
              />
            </div>
            {/* <div className="homepage_slider slider_homepage">
              <Slider title={"Featured content"} sliderData={sliderData} />
            </div> */}

            {plan == "" ? null : (
              <RequestFormModal
                setPlan={setPlan}
                plan={plan}
                showThanksPage={showThanksPage}
                setShowThanksPage={setShowThanksPage}
                product="automate"
                fromPage="automate-home"
              />
            )}
          </Layout>
        </section>
      </Container>
    </>
  );
}
// export function AutomateTestimonialsMobile(props) {
//   const additionalClassName = props.additionalClassName;
//   return (
//     <section
//       className={`w-100 float-left automate-testimonials-container ${
//         additionalClassName ? additionalClassName : ""
//       } `}
//     >
//       <div
//         className="container
//       "
//       >
//         <div className="testimonial_feedback_box_mobile home_assistant_tesimonials_content_mobile">
//           <h3 className="font-section-sub-header text-align-center mb-3">
//             See how companies are automating manual tasks with workativ
//           </h3>
//           <div className="testimonial_feedback_top_content_box_mobile">
//             <p className="font-section-normal-text-testimonials line-height-18">
//               <span>
//                 <img
//                   src={quotes}
//                   className="quotes_image_testimonial h-auto"
//                 ></img>
//               </span>
//               After using a few different automation workflow programs, we
//               decided to go with Workativ, for its’ easy-to-use interface and
//               systems integration. Using Workativ feels like it was truly
//               designed with the user in mind. More importantly the Workativ team
//               has shown tremendous customer support allowing us to continue to
//               expand their software’s capabilities to meet our needs.
//             </p>
//             <p className="font-section-normal-text-testimonials line-height-18">
//               The Workativ team always considers the users feed backs and needs
//               to create brand new features allowing us to design out the exact
//               workflow we need. With their support we have been able to automate
//               our onboarding, offboarding, and up keeping our employee data
//               across all systems.
//             </p>
//             <p className="font-section-normal-text-testimonials line-height-18">
//               We look forward to further exploring the world of automation with
//               their help!
//             </p>
//           </div>

//           <div className="automate-testimonials-left-grid align-self-center">
//             <img
//               src={cahe_director}
//               loading="lazy"
//               alt="cahe_manager"
//               className="automate_testimonial_director mb-3"
//             ></img>
//             <h3 className="font-section-sub-header-bold mb-0 color-director">
//               Robert Zeitz{" "}
//             </h3>
//             <h4 className="font-section-automate-normal-text-testimonials mb-3 text-center line-height-18 color-light-grey">
//               Director of Cloud Applications <br />& Service Delivery
//             </h4>
//             <img
//               src={cahe_logo}
//               loading="lazy"
//               alt="cahe_manager"
//               className="automate_testimonial_logo"
//             ></img>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
// export function AutomateTestimonials(props) {
//   const additionalClassName = props.additionalClassName;
//   return (
//     <section
//       className={`w-100 float-left automate-testimonials-container ${
//         additionalClassName ? additionalClassName : ""
//       } `}
//     >
//       <div className="container">
//         <h2 className="font-section-sub-header text-center mb-4 heading_goto_testimonials_new">
//           See how companies are automating <br />
//           manual tasks with workativ
//         </h2>
//         <div className="testimonial_feedback_box new_goto_testimonial_box">
//           <div className="automate-testimonials-left-grid align-self-center">
//             <img
//               src={cahe_director}
//               loading="lazy"
//               alt="cahe_manager"
//               className="automate_testimonial_director mb-3"
//             ></img>
//             <h3 className="font-section-sub-header-bold mb-0 color-director">
//               Robert Zeitz{" "}
//             </h3>
//             <h4 className="font-section-automate-normal-text-testimonials mb-3 line-height-18 text-center color-light-grey">
//               Director of Cloud Applications & Service Delivery
//             </h4>
//             <img
//               src={cahe_logo}
//               loading="lazy"
//               alt="cahe_manager"
//               className="automate_testimonial_logo"
//             ></img>
//           </div>
//           <div className="testimonial_feedback_right_content_box pl-0">
//             <p className="font-section-normal-text-testimonials line-height-18">
//               <span>
//                 <img
//                   src={quotes}
//                   className="quotes_image_testimonial h-auto"
//                 ></img>
//               </span>
//               After using a few different automation workflow programs, we
//               decided to go with Workativ, for its’ easy-to-use interface and
//               systems integration. Using Workativ feels like it was truly
//               designed with the user in mind. More importantly the Workativ team
//               has shown tremendous customer support allowing us to continue to
//               expand their software’s capabilities to meet our needs.
//             </p>
//             <p className="font-section-normal-text-testimonials line-height-18">
//               The Workativ team always considers the users feed backs and needs
//               to create brand new features allowing us to design out the exact
//               workflow we need. With their support we have been able to automate
//               our onboarding, offboarding, and up keeping our employee data
//               across all systems.
//             </p>
//             <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
//               We look forward to further exploring the world of automation with
//               their help!
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function SwipeableTop() {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [activeSlider, setActiveSlider] = useState(0);
  const slider = useRef(null);
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("swiped left");
      slider.current.slideNext();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      console.log("swiped right");
      slider.current.slidePrev();
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const _slidePrev = () => {
    console.log("slider.current", slider.current);
    slider.current.slidePrev();
  };

  const _slideNext = () => {
    slider.current.slideNext();
  };

  const _goToSlide = (slideNo) => {
    slider.current.goToSlide(slideNo - 1);
  };

  const onSelect = (item) => {
    setActiveSlider(item);
  };

  return (
    <section className="float-left ibm-watson-top-second-section automate-home-slider-section">
      <div className="container">
        <h2
          className="font-section-sub-header-automate"
          style="text-align: center;"
        >
          The modern workflow automation platform
        </h2>
        <p
          className="font-section-normal-text-testimonials mb-3 pb-0"
          style="text-align: center;"
        >
          Automation made easy for all users, across business functions and
          apps.
        </p>
        <div
          className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header chat_img_header ${
            isSmall ? "" : "pl-0"
          }`}
        >
          <div className="features_change_img mobile-display-hide desktop_slider_cc">
            <div
              className="chat-channel assistant_change_img feature_cc_slider mb-0 width-100 slider_automate_new"
              {...handlers}
            >
              <ReactBootstrapCarousel
                animation={true}
                autoplay={true}
                slideshowSpeed={7000}
                defaultActiveIndex={0}
                indicators={false}
                onSelect={onSelect}
                ref={slider}
                version={4}
                controls={false}
              >
                <div className="corousel-image-center">
                  <img
                    className="d-block mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_1}
                    alt="workflow automation platform"
                  />
                </div>
                <div className="corousel-image-center">
                  <img
                    className="d-block  mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_2}
                    alt="workflow automation platform"
                  />
                </div>
                <div className="corousel-image-center">
                  <img
                    className="d-block  mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_3}
                    alt="workflow automation platform"
                  />
                </div>
                <div className="corousel-image-center">
                  <img
                    className="d-block  mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_4}
                    alt="workflow automation platform"
                  />
                </div>
              </ReactBootstrapCarousel>
              <div className="row padding-top-bottom-20 mb-3 pt-0">
                <div className="swipe-chat-channel">
                  <div
                    className={
                      activeSlider !== 0 ? `swipe-one active` : "swipe-one"
                    }
                    tabindex="1"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (activeSlider !== 0) {
                        _slidePrev();
                      }
                    }}
                  >
                    <FeatureArrowWithColor
                      fillColor={activeSlider !== 0 ? "#2468F6" : "#B7B7B7"}
                      className="swipe-icon ar-left rotate-180"
                    />
                  </div>
                  <div
                    className={
                      activeSlider === 0 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      _goToSlide(1);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider === 1 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      _goToSlide(2);
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider === 2 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      _goToSlide(3);
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider === 3 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      _goToSlide(4);
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider !== 3 ? `swipe-one active` : "swipe-one"
                    }
                    tabindex="2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (activeSlider !== 3) {
                        _slideNext();
                      }
                    }}
                  >
                    <FeatureArrowWithColor
                      fillColor={activeSlider !== 3 ? "#2468F6" : "#B7B7B7"}
                      className="swipe-icon ar-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="automate-slider-bottom-main-container">
          <div className="automate-slider-bottom-left-div">
            <div className="automate-slider-left-first-div">
              <div className="automate-slider-bottom-image">
                <img
                  loading="lazy"
                  src={slider_icon_1}
                  alt="simple and intuitive"
                ></img>
              </div>
              <div className="automate-slider-bottom-content">
                <h3 className="font-section-sub-header-small">
                  Simple and Intuitive
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Create app integrations and workflows using simple UI
                  interface. No coding or developer support required.
                </p>
              </div>
            </div>
            <div className="automate-slider-left-scnd-div">
              <div className="automate-slider-bottom-image">
                <img
                  loading="lazy"
                  src={slider_icon_3}
                  alt="100+ connectors"
                ></img>
              </div>
              <div className="automate-slider-bottom-content">
                <h3 className="font-section-sub-header-small">
                  100+ Connectors
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Connect 100+ cloud apps. Use app triggers, webhooks to run
                  your workflows.
                </p>
              </div>
            </div>
          </div>
          <div className="automate-slider-bottom-right-div">
            <div className="automate-slider-right-first-div">
              <div className="automate-slider-bottom-image">
                <img
                  loading="lazy"
                  src={slider_icon_2}
                  alt="powerful tools"
                ></img>
              </div>
              <div className="automate-slider-bottom-content">
                <h3 className="font-section-sub-header-small">
                  Powerful Tools
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Format data, add conditional logic, set approvals, or time
                  intervals into your workflows.
                </p>
              </div>
            </div>
            <div className="automate-slider-right-scnd-div">
              <div className="automate-slider-bottom-image">
                <img
                  loading="lazy"
                  src={slider_icon_4}
                  alt="secure & reliable"
                ></img>
              </div>
              <div className="automate-slider-bottom-content">
                <h3 className="font-section-sub-header-small">
                  Secure & Reliable
                </h3>
                <p className="font-section-normal-text-testimonials line-height-18">
                  Data encryption at rest and transit, data retention controls
                  and audit logs. ISO 27001 certified.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-integrations-links-automate-home">
          <a
            className="app-integrations-url-navigate-automate-home font-section-normal-text-medium-link text-decoration-underline text-underline-offset color-anchor-blue"
            href="/workflow-automation-platform/workflow-automation-feature"
          >
            See all features &nbsp; &#8594;
          </a>
        </div>
      </div>
    </section>
  );
}
function CenterAutomate() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="float-left automate-center-content-main-container">
      <div className="automate-center-main-container container">
        <div className="automate-center-header">
          <h3 className="font-section-sub-header">What you can do</h3>
        </div>
        <div className="automate-center-image-content-first">
          <div className="automate-center-content">
            <h4 className="font-section-sub-header-small color-light-gray">
              Simple Workflow
            </h4>
            <p className="font-section-normal-text-testimonials line-height-18">
              Create simple one-to-one workflow automation, or just sync data
              between two apps.
            </p>
          </div>
          <div className="automate-center-image">
            {isSmall ? (
              <img
                loading="lazy"
                src={Section2_1_mobile}
                alt="simple_workflow"
              ></img>
            ) : (
              <img loading="lazy" src={Section2_1} alt="simple_workflow"></img>
            )}
          </div>
        </div>
        <div className="automate-center-image-content-second">
          <div className="automate-center-image">
            {isSmall ? (
              <img
                loading="lazy"
                src={Section2_2_mobile}
                alt="simple_workflow"
              ></img>
            ) : (
              <img loading="lazy" src={Section2_2} alt="simple_workflow"></img>
            )}
          </div>
          <div className="automate-center-content">
            <h4 className="font-section-sub-header-small color-light-gray">
              Complex Workflow
            </h4>
            <p className="font-section-normal-text-testimonials line-height-18">
              Create multi-step workflows, add conditions, and actions across
              several applications.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
function CenterAutomateMobile() {
  return (
    <section className="float-left automate-center-content-main-container">
      <section className="float-left width-100 first-section-mobile-automate-home">
        <div className="automate-center-main-container container">
          <div className="automate-center-header">
            <h3 className="font-section-sub-header">What you can do</h3>
          </div>
          <div className="automate-center-image-content-first">
            <div className="automate-center-content">
              <h4 className="font-section-sub-header-small color-light-gray">
                Simple Workflow
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Create simple one-to-one workflow automation, or just sync data
                between two apps.
              </p>
            </div>
            <div className="automate-center-image">
              <img
                loading="lazy"
                src={Section2_1_mobile}
                className="image-mobile-automate-home-23"
                alt="simple_workflow"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="float-left width-100 second-section-mobile-automate-home">
        <div className="automate-center-main-container container">
          <div className="automate-center-image-content-second">
            <div className="automate-center-image">
              <img
                loading="lazy"
                src={Section2_2_mobile}
                className="image-mobile-automate-home-23"
                alt="simple_workflow"
              ></img>
            </div>
            <div className="automate-center-content">
              <h4 className="font-section-sub-header-small color-light-gray">
                Complex Workflow
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Create multi-step workflows, add conditions, and actions across
                several applications.
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
export function CustomerMobile() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="float-left width-100 why-customer-banner-mobile-section why-customer-banner-mobile-section-automate">
      {isSmall ? (
        <div className="container">
          <div className="why-automate-customer-banner">
            <h5 className="font-section-sub-header-small banner-header-why-automate">
              Join leading innovators
            </h5>
            <img
              loading="lazy"
              src={require("../assets/automate/home/automate_clients_logo_mobile.svg")}
            />
          </div>
        </div>
      ) : null}
    </section>
  );
}
function InegrationApps(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="apps_integrations apps_integrations_home">
      <div className="cogni_fav_apps cc_feature_apps automate-new-integrations-apps">
        <div className="container">
          <div className="marketplace_integrations_icons automate-new-integrations-apps padding-integrations-automate">
            <div className="cc_feature_apps_header">
              <h3 className="font-section-header mb-1  integrations-apps-header-automate">
                Connect your favorite apps
              </h3>
              {isSmall ? (
                <p className="font-section-normal-text-testimonials integrations-apps-paragaraph-automate">
                  All popular apps
                </p>
              ) : (
                <p className="font-section-normal-text-testimonials line-height-18 integrations-apps-paragaraph-automate">
                  All popular apps for - ITSM, HR, Access Management, CRM, MDM,
                  Document Management, Expense Management, Email Management, SMS
                  Management, Notifications, and Collaboration Apps.
                </p>
              )}
            </div>
            <div className="row mobile-padding-reduce-automate">
              <div className="cogni_fav_apps_color app-list-automate-home">
                {appsName.map((apps) => (
                  <div className="card_office_cc">
                    <a href={apps.link}>
                      <div className="card_office">
                        <img loading="lazy" src={apps.image} alt={apps.alt} />
                      </div>
                    </a>
                    <a href={apps.link}>
                      <h6>{apps.header}</h6>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="app-integrations-links-automate-home">
              <a
                className="app-integrations-url-navigate-automate-home font-section-normal-text-medium-link text-decoration-underline text-underline-offset color-anchor-blue"
                href="/workflow-automation-platform/integrations/"
              >
                See all popular Integrations &nbsp; &#8594;
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export function OnboardingSlider({
  tabContent,
  imagesToPreload,
  onboardingSliderWithClick,
}) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducer_November,
    tabContent,
    tabSliderInit_November
  );
  const ComponentToDisplay =
    tabContent[currentTabState.activeIndex].desktopContent;

  const allHeaders = tabContent.map((tabData) => tabData.header);

  const betaClick = onboardingSliderWithClick;

  const isCurrentTabLast = isLastTab_November(currentTabState);

  const isCurrentTabFirst = isFirstTab_November(currentTabState);

  usePreloadImages_November(imagesToPreload);
  return (
    <section className="w-100 float-left onboarding_main_container automate_update_onboarding_main">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-2">
          Go live in minutes with our onscreen onboarding
        </h4>
        <h5 className="font-section-normal-text-medium sign_up_font_size_reduce text-align-center color-light-black mb-4">
          Sign up for FREE&nbsp;
          <a
            style={{ color: "#1857DC", cursor: "pointer" }}
            onClick={betaClick}
            className="automate_onboard_here_signup"
          >
            {" "}
            <span> here </span>
            <img
              loading="lazy"
              src={OnboardHere}
              className="onboard_here_arrow_automate"
            ></img>
          </a>
        </h5>
        <div className="onboard_heading_container mb-4">
          {allHeaders.map((header, i) => (
            <div className="sub_container_heading_switch text-align-center">
              <h6
                className={
                  currentTabState.activeIndex === i
                    ? "font-section-normal-text-medium-small onboard_switch_top_click color-blue"
                    : "font-section-normal-text-medium-small onboard_switch_top color-grey"
                }
              >
                Step {i + 1}
              </h6>
              <h5
                onClick={() => dispatchTabAction({ type: "to", toTab: i })}
                className={
                  currentTabState.activeIndex === i
                    ? "font-section-normal-text-medium support_switch_heading_click cursor-pointer"
                    : "font-section-normal-text-medium support_switch_heading color-grey cursor-pointer"
                }
              >
                {header}
              </h5>
            </div>
          ))}
        </div>
        <div className="parent_container_support_feature_onboard">
          <div
            className={
              isCurrentTabFirst
                ? "left_arrow_round_home onboarding_slide_arrow_automate_home cursor-pointer arrow-disabled"
                : "left_arrow_round_home onboarding_slide_arrow_automate_home hover_color_change_automate_onboard cursor-pointer"
            }
            onClick={() => dispatchTabAction({ type: "prev" })}
          >
            <img src={arrow_round} />
          </div>
          <div className="onboard_content_container">
            <ComponentToDisplay />
          </div>
          <div
            className={
              isCurrentTabLast
                ? "right_arrow_round_home onboarding_slide_arrow_automate_home cursor-pointer arrow-disabled"
                : "right_arrow_round_home onboarding_slide_arrow_automate_home hover_color_change_automate_onboard cursor-pointer"
            }
            onClick={() => dispatchTabAction({ type: "next" })}
          >
            <img src={arrow_round} />
          </div>
        </div>
      </div>
    </section>
  );
}
export function OnboardingMobile({
  tabContent,
  imagesToPreload,
  onboardingSliderWithClick,
}) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducer_November,
    tabContent,
    tabSliderInit_November
  );

  // const accordElem = React.useRef(null);
  const betaClick = onboardingSliderWithClick;

  const [executeScroll, accordElem] = useScroll_November();

  const ComponentToDisplay =
    tabContent[currentTabState.activeIndex].mobileContent;

  const allHeaders = tabContent.map((tabData) =>
    tabData.mobileHeader !== undefined ? tabData.mobileHeader : tabData.header
  );

  usePreloadImages_November(imagesToPreload);

  const firstUpdate = useFirstUpate();

  React.useEffect(() => {
    if (!firstUpdate) executeScroll();
  }, [currentTabState.activeIndex]);

  return (
    <section className="w-100 float-left onboarding_main_container automate_update_onboarding_main">
      <div className="container">
        <h4 className="font-section-sub-header text-align-center mb-2">
          Go live in minutes with our onscreen onboarding
        </h4>
        <h5 className="font-section-normal-text-medium sign_up_font_size_reduce text-align-center color-light-black mb-4">
          Sign up for FREE&nbsp;
          <a
            style={{ color: "#1857DC", cursor: "pointer" }}
            onClick={betaClick}
            className="automate_onboard_here_signup"
          >
            {" "}
            <span> here </span>
            <img
              loading="lazy"
              src={OnboardHere}
              className="onboard_here_arrow_automate"
            ></img>
          </a>
        </h5>

        {allHeaders.map((header, index) => (
          <React.Fragment>
            <div
              className={
                currentTabState.activeIndex === index
                  ? "onboarding_heading_container_clicked mb-4 color-black"
                  : "onboarding_heading_container mb-4 color-grey"
              }
              onClick={() => {
                dispatchTabAction({ type: "to", toTab: index });
                // accordElem.current.scrollIntoView({
                //   behavior: "smooth",
                //   block: "start",
                //   inline: "nearest",
                // });
                // executeScroll()
              }}
            >
              <h5 className="font-section-normal-text-medium mb-0">
                <span className="font-section-normal-text-medium-pricing _step_onboarding_mobile">
                  {" "}
                  Step {index + 1}
                </span>{" "}
                - {header}
              </h5>
              <img
                src={arrow_round_mobile}
                className={
                  currentTabState.activeIndex === index
                    ? "arrow_opened_up"
                    : "arrow_closed_down color-grey"
                }
              />
            </div>
            {currentTabState.activeIndex === index ? (
              <div
                className={`onboard_content_container ${
                  currentTabState.activeIndex === currentTabState.totalTabs - 1
                    ? "mb-0"
                    : ""
                }`}
                ref={accordElem}
              >
                <ComponentToDisplay />
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}
const tabsForOnBoarding_November = [
  {
    header: "Sign up",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Select how your workflows are triggered and set app actions
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          Select how your workflows are triggered and set app actions
        </p>
        <img src={onboard_one} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Create Workflows",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Create Workflows
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Select how your workflows are triggered and set app actions
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Create Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Select how your workflows are triggered and set app actions
        </p>

        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect Apps",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Connect Apps</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Connect your workflows with apps account securely
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect Apps</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connect your workflows with apps account securely
        </p>
        <img src={onboard_three} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Active & Go-live",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Active & Go-live
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test and activate your workflows to go-live seamlessly
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Active & Go-live</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test and activate your workflows to go-live seamlessly
        </p>

        <img src={onboard_four} alt="Connect App Workflows"></img>
      </>
    ),
  },
];
const tabsForOnBoardingImagesDesktop_November = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
];
const useScroll_November = () => {
  const elRef = React.createRef();
  const executeScroll = () => {
    console.log("DOING :: SCROLLING TO", elRef.current);
    // elRef.current.scrollIntoView()
    // fancyAScroll(elRef.current, 500, 140)
    window.scrollTo({
      behavior: "smooth",
      top:
        elRef.current.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        140, // for sticky header
    });
  };

  return [executeScroll, elRef];
};
const useFirstUpate = () => {
  const firstUpdate = React.useRef(true);
  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // console.log("componentDidUpdateFunction");
  });

  return firstUpdate.current;
};
function tabSliderInit_November(tabData) {
  return { activeIndex: 0, totalTabs: tabData.length };
}

const isLastTab_November = (currentTabState) => {
  return currentTabState.activeIndex === currentTabState.totalTabs - 1;
};

const isFirstTab_November = (currentTabState) => {
  return currentTabState.activeIndex === 0;
};

const tabSliderReducer_November = (state, action) => {
  // console.log("DOING ::: reducer ", state, action)
  switch (action.type) {
    case "to": {
      // console.log("DOING ::: reducer :: 1", state, action, action.toTab < state.totalTabs, action.toTab >= 0)
      if (action.toTab < state.totalTabs && action.toTab >= 0) {
        const toReturn = { ...state, activeIndex: action.toTab };
        // console.log("DOING ::: reducer :: 2", toReturn)
        return toReturn;
      }
      // console.log("DOING ::: reducer :: 3");
      return state;
    }
    case "next":
      if (state.activeIndex < state.totalTabs - 1) {
        return { ...state, activeIndex: state.activeIndex + 1 };
      }
      return state;
    case "prev":
      if (state.activeIndex < state.totalTabs && state.activeIndex > 0) {
        return { ...state, activeIndex: state.activeIndex - 1 };
      }
      return state;
    case "reset":
      return { ...state, activeIndex: 0 };
    default:
      throw new Error("Invalid action");
  }
};

export const usePreloadImages_November = (imagesToLoad) => {
  React.useEffect(() => {
    if (imagesToLoad.length > 0) {
      imagesToLoad.forEach((item) => {
        const img = new Image();
        img.src = item;
      });
    }
  }, []);
};

const appsName = [
  {
    image: okta,
    header: "Okta",
    alt: "Okta",
    link: "/workflow-automation-platform/integrations/okta",
  },
  {
    image: box,
    header: "Box",
    alt: "box",
    link: "/workflow-automation-platform/integrations/box",
  },
  {
    image: authO,
    header: "Auth0",
    alt: "auth0",
    link: "/workflow-automation-platform/integrations/auth0",
  },
  {
    image: bamboo,
    header: "Bamboo HR",
    alt: "bamboo",
    link: "/workflow-automation-platform/integrations/bamboohr",
  },
  {
    image: servicenow,
    header: "Servicenow",
    alt: "servicenow",
    link: "/workflow-automation-platform/integrations/servicenow",
  },
  {
    image: ms_office,
    header: "Office365",
    alt: "ms_office",
    link: "/workflow-automation-platform/integrations/office-365",
  },
  {
    image: jira,
    header: "Jira",
    alt: "jira",
    link: "/workflow-automation-platform/integrations/jira",
  },
  {
    image: freshservice,
    header: "Freshservice",
    alt: "freshservice",
    link: "/workflow-automation-platform/integrations/freshservice",
  },
  {
    image: ad,
    header: "Microsoft Azure AD",
    alt: "Microsoft Azure AD",
    link: "/workflow-automation-platform/integrations/active-directory",
  },
  {
    image: zendesk,
    header: "Zendesk",
    alt: "zendesk",
    link: "/workflow-automation-platform/integrations/zendesk",
  },
  {
    image: jumpcloud,
    header: "JumpCloud",
    alt: "JumpCloud",
    link: "/workflow-automation-platform/integrations/jumpcloud",
  },
  {
    image: intune,
    header: "Microsoft Intune",
    alt: "Microsoft Intune",
    link: "/workflow-automation-platform/integrations/microsoft-intune",
  },
  {
    image: hexnode,
    header: "Hexnode",
    alt: "Hexnode",
    link: "/workflow-automation-platform/integrations/hexnode",
  },
  {
    image: otrs,
    header: "OTRS",
    alt: "OTRS",
    link: "/workflow-automation-platform/integrations/otrs",
  },
  {
    image: jamf,
    header: "JAMF",
    alt: "jamf",
    link: "/workflow-automation-platform/integrations/jamf",
  },
  {
    image: onelogin,
    header: "OneLogin",
    alt: "OneLogin",
    link: "/workflow-automation-platform/integrations/onelogin",
  },
  {
    image: zoho_people,
    header: "Zoho People",
    alt: "Zoho People",
    link: "/workflow-automation-platform/integrations/zoho-people",
  },
  {
    image: ivanti,
    header: "Ivanti",
    alt: "Ivanti",
    link: "/workflow-automation-platform/integrations/ivanti",
  },
];
export const sliderData = [
  {
    title: "Call deflection",
    alt: "Call deflection",
    image: Feature_Content_1,
    content: "Integrate BambooHR with Workativ and simplify HRMS ",
    link: "Learn more",
    url:
      "https://blog.workativ.com/chatbot-best-practices-for-designing-a-conversational-experience-with-workativ-assistant/",
  },
  {
    title: "Solution",
    alt: "Solution",
    image: Feature_Content_2,
    content: "Integrate and Automate your favorite apps with Salesforce CRM",
    link: "Learn more",
    url:
      "https://blog.workativ.com/get-over-the-pandemic-standstill-leapfrog-with-digital-assistants-that-enable-business-continuity-during-covid-19/",
  },
  {
    title: "Watch",
    alt: "Watch",
    image: Feature_Content_3,
    content: "Zendesk integration enables employees to view their helpdesk.",
    link: "Learn more",
    url: "https://youtu.be/6TCv8b0dju8",
  },
  {
    title: "Integration",
    alt: "Integration",
    image: Feature_Content_4,
    content:
      "10 Major IT Workflow Automation Benefits, Use cases You Should Know",
    link: "Read Blog",
    url:
      "https://blog.workativ.com/8-best-it-workflow-automation-practices-to-follow-for-chatbots-designed-with-workativ-assistant/",
  },
  {
    title: "Cost Reduction",
    alt: "Cost Reduction",
    image: Feature_Content_5,
    content: "8 Best IT Workflow Automation Practices to follow for Chatbots.",
    link: "Learn more",
    url: "/conversational-ai-platform/chatbot-whitepaper",
  },
];
const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ Automate",
  url: "ttps://workativ.com/workflow-automation-platform",
  description:
    " Workativ Automate: Add workflow automation to your favourite conversational chatbots like IBM Watson, Dialogflow, Amazon Lex, Microsoft LUIS etc or simply use our triggers or webhooks to automate workflows between apps. No coding required!",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support.  ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};
